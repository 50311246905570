import { ajax } from "rxjs/ajax";
import queryString from "query-string";

import env from "config/env";

const { STATIC_SITE_URL } = env;

const getCartRecommendations$ = ({ locale = "en", hotelCode }) => {
  const qs = queryString.stringify({
    language: locale,
    owsCode: hotelCode,
    timestamp: Date.now(),
  });

  return ajax.getJSON(
    `${STATIC_SITE_URL}/alt/apps/fshr/feeds/booking/cartRecommendations?${qs}`
  );
};

export default getCartRecommendations$;
