import { ajax } from "rxjs/ajax";
import queryString from "query-string";

import env from "config/env";

const { STATIC_SITE_URL } = env;

export default function getTransportationAmenitiesContent$({
  locale = "en",
  hotelCode,
  startDate,
  endDate,
  adults,
  children,
  sourceName,
}) {
  const qs = queryString.stringify({
    language: locale,
    owsCode: hotelCode,
    startDate,
    endDate,
    adults,
    children,
    sourceName,
    timestamp: Date.now(),
    version: 3,
  });

  // https://www.project1.stage.fsweb.ca/alt/apps/fshr/feeds/booking/transportationAmenities?language=en&owsCode=LON140&startDate=2023-07-04
  return ajax.getJSON(
    `${STATIC_SITE_URL}/alt/apps/fshr/feeds/booking/transportationAmenities?${qs}`
  );
}
