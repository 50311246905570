import React from "react";
import { parse, format } from "date-fns";
import * as dateFnLocales from "date-fns/locale";
import classNames from "classnames";
import get from "lodash/fp/get";

import { useTranslation } from "hooks";
import { getDateFnsLocale } from "config/languages";
import { formatArrivalTime } from "utils/utils";
import { getDefaultCheckInTime } from "utils";
import { ToggleBlock } from "Profile/components";
import { SignInModal } from "Authentication";
import TripDetailsForm from "./TripDetailsForm";

function DateText({ type = "checkin", date, time }) {
  const { t, locale } = useTranslation();

  const label = type === "checkin" ? "Check In" : "Check Out";
  const dateLabel = format(
    parse(date, "yyyy-MM-dd", new Date()),
    "eee, MMM dd, yyyy",
    { locale: dateFnLocales[getDateFnsLocale(locale)] }
  );

  return (
    <div className={`fs-trip__details__dates--${type}`}>
      <div className="fs-trip__details__date-label">{t(label)}</div>
      <div className="fs-trip__details__date">
        <span>{dateLabel}</span>
        {time && <span className="fs-trip__details__time"> - {time}</span>}
      </div>
    </div>
  );
}

export default function TripDetails({
  canChangeDates = false,
  upcomingTrip,
  propertyContent,
  onClick,
  onSubmit,
  showSavingSpinner,
  isUnauthenticated,
}) {
  const { t } = useTranslation();

  const checkInTime =
    formatArrivalTime(
      get(["hotelProducts", "0", "checkInTime"], upcomingTrip)
    ) || getDefaultCheckInTime(propertyContent);

  return (
    <div className="fs-trip__details">
      <h2>{t("trip details")}</h2>
      <div className="fs-trip__details__dates">
        <DateText date={upcomingTrip.startDate} time={checkInTime} />
        <DateText type="checkout" date={upcomingTrip.endDate} />
        {canChangeDates && onClick && (
          <>
            <div className="fs-trip__details__change-dates">
              <SignInModal
                id="changeDates"
                buttonLabel={t("Change Dates")}
                type="CTA"
                kind="link"
                onClick={onClick}
                showToastOnNextView
              />
            </div>
            <div className="fs-trip__details__arrival-time">
              <ToggleBlock
                type="edit"
                label={({ isOpen, isOpening, toggle }) => (
                  <div className="fs-toggle-block__label">
                    {isUnauthenticated ? (
                      <button
                        className={classNames(
                          "fs-toggle-button--light fs-toggle-button-light--edit",
                          {
                            "fs-toggle-button--light-active":
                              isOpen || isOpening,
                          }
                        )}
                        onClick={toggle}
                        type="button"
                        aria-label={t("Edit Arrival Time")}
                      >
                        {t("Edit Arrival Time")}
                      </button>
                    ) : (
                      <SignInModal
                        id="editArrivalTimeUpcomingTrip"
                        onClick={toggle}
                        className={classNames(
                          "fs-toggle-button--light fs-toggle-button-light--edit",
                          {
                            "fs-toggle-button--light-active":
                              isOpen || isOpening,
                          }
                        )}
                        type="CustomButton"
                        buttonLabel={t("Edit Arrival Time")}
                      />
                    )}
                  </div>
                )}
              >
                {({ close }) => (
                  <TripDetailsForm
                    close={close}
                    upcomingTrip={upcomingTrip}
                    propertyContent={propertyContent}
                    onSubmit={onSubmit}
                    showSavingSpinner={showSavingSpinner}
                  />
                )}
              </ToggleBlock>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
