import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import get from "lodash/fp/get";
import Cookies from "js-cookies";
import axios from "axios";
import { errorCodes, getErrorCodeForTranslations } from "utils/errorCodes";
import { getFindAHotelLink } from "utils/externalLinks";
import env from "config/env";
import { getIsoCodeForLocale } from "config/languages";
import * as routes from "Authentication/authenticationRoutes";
import { useTranslation, useView } from "hooks";
import {
  selectPropertyShortName,
  selectPropertyImage,
} from "store/propertyContent";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { Button, Checkbox, ErrorMessage, TextInput } from "Profile/components";
import useProperties from "hooks/useProperties";
import Typeahead from "Profile/ProfileView/Typeahead";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import { selectAreAllRequestsComplete } from "store/selectors";
import AuthenticationLayout from "../AuthenticationLayout";
import UnauthenticatedUpcomingTrip from "./UnauthenticatedUpcomingTrip";
import GetInTouch from "./GetInTouch";

const { IMAGE_BASE_URL, TRETAIL_API_BASE_URL } = env;

function getBookingById({ locale, bookingId }) {
  return axios({
    url: `${TRETAIL_API_BASE_URL}/bookingsCust/${bookingId}`,
    method: "GET",
    withCredentials: true,
    headers: {
      "Accept-Language": getIsoCodeForLocale(locale || "en"),
      "Content-Type": "application/json",
    },
  }).then((response) => ({
    hotelProducts: [],
    eventProducts: [],
    ...response.data,
  }));
}

function retrieveBooking({ locale, reservationId, hotelCode, surname }) {
  return axios({
    url: `${TRETAIL_API_BASE_URL}/bookingHistory/retrieval`,
    method: "POST",
    withCredentials: true,
    headers: {
      "Accept-Language": getIsoCodeForLocale(locale || "en"),
      "Content-Type": "application/json",
    },
    data: {
      bookingReference: reservationId,
      hotelCode,
      supplierCode: "IBE",
      surname,
    },
  }).then(get("data"));
}

function getFSSecurityMX() {
  const fsSecurityMx = Cookies.getItem("FSSecurityMX");
  return fsSecurityMx ? JSON.parse(atob(fsSecurityMx)) : null;
}

function deleteBookingIdCookie() {
  let domain;
  const domainParts = window.location.hostname.split(".");
  if (domainParts.length > 1) {
    domainParts.shift();
    domain = `.${domainParts.join(".")}`;
  }
  Cookies.removeItem("bookingId");
  Cookies.removeItem("bookingId", "/", domain);
}

function TheForm({
  pageTitle,
  isFindReservations = false,
  hotelCode,
  reservationId,
  onRetrieveBooking,
}) {
  const { t, locale } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [supplierError, setSupplierError] = useState(null);
  const [apiError, setApiError] = useState(null);

  let errorParams = [];
  if (supplierError?.errorCode === errorCodes.COULD_NOT_RETRIEVE) {
    errorParams = [getFindAHotelLink(locale)];
  }

  const { properties, isLoading: isLoadingProperties } = useProperties();

  const propertyShortName = useSelector(selectPropertyShortName(hotelCode));
  const isBeverlyWilshire =
    propertyShortName === "Beverly Wilshire, A Four Seasons Hotel";
  const propertyImage = useSelector(selectPropertyImage(hotelCode));

  const isPropertyContentRequestComplete = useSelector(
    selectAreAllRequestsComplete([
      ["propertyContent", "requestStates", hotelCode],
    ])
  );

  const showLoadingIndicator =
    isLoadingProperties || (hotelCode && !isPropertyContentRequestComplete);

  const { control, register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      surname: "",
      hotelCode: hotelCode || "",
      reservationId: reservationId || "",
      confirmation: false,
    },
  });

  const formErrors = {
    ...(apiError?.field
      ? {
          [apiError.field]: {
            message: t(apiError.userMessage || apiError.errorCode),
          },
        }
      : {}),
    ...errors,
  };

  const onSubmit = (formValues) => {
    let redirectTo = location;
    if (!hotelCode || !reservationId) {
      redirectTo = isFindReservations
        ? routes.findReservationsPath.to({
            locale,
            hotelCode: hotelCode || formValues.hotelCode,
            reservationId: formValues.reservationId,
          })
        : routes.unauthenticatedBookingPath.to({
            locale,
            hotelCode: hotelCode || formValues.hotelCode,
            reservationId: formValues.reservationId,
          });
    }

    const props = {
      reservationId: formValues.reservationId,
      hotelCode: formValues.hotelCode || hotelCode,
      surname: formValues.surname,
    };

    setApiError(null);
    setSupplierError(null);

    return retrieveBooking({
      locale,
      ...props,
    })
      .then(() => onRetrieveBooking(props))
      .then(() => history.replace(redirectTo))
      .then(() => deleteBookingIdCookie())
      .catch(({ response }) => {
        setApiError(get(["data", "apiErrors", 0])(response));
        setSupplierError(get(["data", "supplierErrors", 0])(response));
      });
  };

  const formInputs = {
    confirmationNumber: () => {
      return (
        <TextInput
          identifier="input-confirmation-number"
          key="reservationId"
          name="reservationId"
          label={
            isFindReservations
              ? `${t("Confirmation number")}*`
              : `${t("Reservation Number")}*`
          }
          register={register({
            required: {
              value: true,
              message: isFindReservations
                ? t("Please enter your confirmation number")
                : t("Please enter your reservation number"),
            },
          })}
          error={formErrors?.reservationId?.message}
        />
      );
    },

    lastName: () => {
      return (
        <TextInput
          identifier="input-last-name"
          key="surname"
          name="surname"
          label={`${t("Last Name")}*`}
          register={register({
            required: {
              value: true,
              message: t("Please enter a last name"),
            },
          })}
          error={formErrors?.surname?.message}
        />
      );
    },

    hotelCode: () => {
      return (
        <Controller
          control={control}
          key="hotelCode"
          name="hotelCode"
          rules={{
            required: {
              value: true,
              message: t("Please select a hotel"),
            },
          }}
          render={({ ref: _ignoredRef, ...renderProps }) => {
            return (
              <Typeahead
                label={
                  isFindReservations
                    ? t("Reservation Location")
                    : t("Hotel Name")
                }
                options={properties}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...renderProps}
                error={formErrors?.hotelCode?.message}
                required
              />
            );
          }}
        />
      );
    },
  };

  const fieldOrder = isFindReservations
    ? ["confirmationNumber", "lastName", "hotelCode"]
    : ["lastName", !hotelCode && "hotelCode", "confirmationNumber"].filter(
        Boolean
      );

  const pageHeading = () => {
    if (isFindReservations) {
      return t("Find A Reservation");
    }
    if (isBeverlyWilshire) {
      return [t("Welcome to"), formatBeverlyWilshire(propertyShortName)]
        .filter(Boolean)
        .join(" - ");
    }

    return [t("Welcome to Four Seasons"), propertyShortName]
      .filter(Boolean)
      .join(" - ");
  };

  if (showLoadingIndicator) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <>
      {formState.isSubmitting && <BookingFlowLoadingIndicator />}

      <AuthenticationLayout
        pageTitle={pageTitle}
        backgroundImageSrc={
          isFindReservations
            ? "/images/profiles_2018/find-reservations/BOR_180_screen-hi-res.jpg"
            : propertyImage && IMAGE_BASE_URL + propertyImage
        }
        enableProfileLinkFlyout={false}
      >
        <div
          className={classNames("view", {
            "find-reservations": isFindReservations,
          })}
        >
          <div className="unauthenticated-upcoming-trip-sign-in">
            <h1
              className="ty-h1"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: pageHeading(),
              }}
            />

            {!isFindReservations && (
              <h3 className="ty-h3">{t("Confirm your details")}</h3>
            )}

            {(supplierError || apiError) && (
              <ErrorMessage className="not-found">
                {t(
                  getErrorCodeForTranslations({
                    errorCode: supplierError?.errorCode || apiError?.errorCode,
                  }),
                  errorParams || []
                )}
              </ErrorMessage>
            )}

            <p className="ty-b1">
              {isFindReservations
                ? t("Please enter your reservation details.")
                : t(
                    "For security purposes, please confirm the details on your reservation."
                  )}
            </p>

            <form name="retrieveBookingForm" onSubmit={handleSubmit(onSubmit)}>
              {fieldOrder.map((field) => {
                return formInputs[field]();
              })}

              <Checkbox
                identifier="confirmation"
                name="confirmation"
                register={register({
                  required: true,
                })}
              >
                {t(
                  "I confirm that I am the guest and/or individual authorized and responsible for the requested reservation and payment details."
                )}
              </Checkbox>

              <div className="actions">
                <Button
                  type="submit"
                  disabled={!formState.isValid || formState.isSubmitting}
                >
                  {isFindReservations ? t("Find Reservation") : t("Continue")}
                </Button>
              </div>

              {isFindReservations && <GetInTouch />}
            </form>
          </div>
        </div>
      </AuthenticationLayout>
    </>
  );
}

export default function UnauthenticatedUpcomingTripView({
  hotelCode,
  reservationId,
  pageTitle,
  isFindReservations = false,
  afterFindReservationRedirectTo,
}) {
  useView("UnauthenticatedUpcomingTripView");
  const { locale } = useTranslation();
  const bookingId = getFSSecurityMX()?.mrbi;

  const [isFetchingBooking, setIsFetchingBooking] = useState(
    Boolean(bookingId)
  );
  const [retrieveBookingProps, setRetrieveBookingProps] = useState({});

  const hasAccessToBooking = Boolean(
    retrieveBookingProps.hotelCode &&
      retrieveBookingProps.surname &&
      retrieveBookingProps.reservationId
  );

  useEffect(() => {
    if (bookingId) {
      setIsFetchingBooking(true);
      getBookingById({ locale, bookingId }).then(
        ({ hotelProducts, customers }) => {
          if (
            hotelProducts[0].reservationId === reservationId &&
            hotelProducts[0].hotelCode === hotelCode
          ) {
            setRetrieveBookingProps({
              hotelCode: hotelProducts[0].hotelCode,
              surname: customers[0].name.surname,
              reservationId: hotelProducts[0].reservationId,
            });
          }
          setIsFetchingBooking(false);
        }
      );
    } else {
      setIsFetchingBooking(false);
      setRetrieveBookingProps({});
    }
  }, [bookingId, hotelCode, locale, reservationId]);

  if (isFetchingBooking) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  if (hasAccessToBooking) {
    if (afterFindReservationRedirectTo) {
      window.location.replace(afterFindReservationRedirectTo);
      return null;
    }

    return (
      <UnauthenticatedUpcomingTrip
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...retrieveBookingProps}
        isFindReservations={isFindReservations}
      />
    );
  }

  return (
    <TheForm
      pageTitle={pageTitle}
      isFindReservations={isFindReservations}
      hotelCode={hotelCode}
      reservationId={reservationId}
      onRetrieveBooking={setRetrieveBookingProps}
    />
  );
}
