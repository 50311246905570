import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPersonalizeData } from "rbf-content-mapping";
import get from "lodash/get";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { STEPS } from "fixtures/constants";
import getInitialArrivalTime from "utils/getInitialArrivalTime";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { getFormattedDateRange } from "BookingFlow/utils";
import isBookingEmployeeRate from "BookingFlow/utils/isBookingEmployeeRate";
import { pluralize } from "utils/utils";
import getWebChatStatus from "utils/getWebChatStatus";
import { selectChatStatus } from "store/appStatus";
import {
  selectBookingInProgressSearchParams,
  selectBookingInProgressId,
  selectBookingWithCancellationRef,
  selectIsBookingCancelled,
  cancelBooking,
  submitConfirmationToolEvt,
} from "store/bookings";
import { selectPreferredPartnerDetails } from "store/preferredPartnerDetails";
import { selectIsUserLoggedIn } from "store/profile";
import { selectPostBookingComments } from "store/bookingComments";
import { selectPropertyAccommodationsContent } from "store/accommodationsContent";
import { selectPropertyOffersContent } from "store/offersContent";
import { selectProperty } from "store/properties";
import { selectPropertyContent } from "store/propertyContent";
import { selectTermsAndConditionsForCurrentBooking } from "store/termsAndConditionsContent";
import {
  selectIsRequestLoading,
  selectIsRequestFailed,
} from "store/apiRequestStates";
import { MediaContext } from "contexts/MediaContext";
import { setViewState, setFoundReservationId } from "store/registration";
import { REGISTER_VIEW_STATES } from "store/registration/registration.slice";
import env from "config/env";
import { setParams } from "store/webChat";

const { MOBILE_APP_URL } = env;

export default function usePersonaliseYourStay() {
  const { t, locale } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const media = useContext(MediaContext);
  const dispatchWithLocale = useDispatchWithLocale();
  const history = useHistory();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const searchParams = useSelector(selectBookingInProgressSearchParams);
  const {
    hotelCode,
    promoCode,
    dates: { checkIn, checkOut },
    ppMode: isPreferredPartners = false,
    ppUrl: ppReturnUrl,
  } = searchParams;

  const { propertyStatusLink, propertyTravelAdvisoryLink } = useSelector(
    selectProperty(hotelCode)
  );
  const propertyContent = useSelector(selectPropertyContent(hotelCode));
  const accommodations = useSelector(
    selectPropertyAccommodationsContent(hotelCode)
  );
  const offers = useSelector(selectPropertyOffersContent(hotelCode));

  const completedBookingId = useSelector(selectBookingInProgressId);
  const completedBooking = useSelector(
    selectBookingWithCancellationRef(completedBookingId)
  );
  const reservationId = get(completedBooking, [
    "hotelProducts",
    0,
    "reservationId",
  ]);
  const isBookingCancelled = useSelector(
    selectIsBookingCancelled(completedBooking.bookingId)
  );
  const isBookingCancelledFailed = useSelector(
    selectIsRequestFailed(cancelBooking.type)
  );
  const isCancellingBooking = useSelector(
    selectIsRequestLoading(cancelBooking.type)
  );
  const termsAndConditions = useSelector(
    selectTermsAndConditionsForCurrentBooking({
      locale,
      currentBooking: completedBooking,
    })
  ).filter(Boolean);
  const postBookingComments = useSelector(
    selectPostBookingComments({ reservationId })
  );
  let { startDate, endDate } = completedBooking;
  if (!startDate) {
    startDate = checkIn;
  }
  if (!endDate) {
    endDate = checkOut;
  }

  const tretailErrors = useSelector(({ bookingComments }) => ({
    apiErrors: bookingComments.apiErrors,
    supplierErrors: bookingComments.supplierErrors,
  }));

  const employeeMode = isBookingEmployeeRate(completedBooking);

  const ppDetails = useSelector(selectPreferredPartnerDetails) || {};

  const { contactDetails } = completedBooking;
  const firstName = get(contactDetails, ["name", "firstName"], "");
  const surname = get(contactDetails, ["name", "surname"], "");
  const contactName = [firstName, surname].filter(Boolean).join(" ");
  const email = get(contactDetails, ["email"], "");
  const phoneNumber = get(contactDetails, ["phones", 0, "number"], "");

  const goToRegisterWithReservation = () => {
    dispatch(setFoundReservationId(reservationId));
    dispatch(setViewState(REGISTER_VIEW_STATES.ENTER_REGISTRATION_DETAILS));
    history.push({
      pathname: authenticationRoutes.register.to({
        locale,
      }),
    });
  };

  const submitConfirmationTool = (e) => {
    e.preventDefault();
    dispatchWithLocale(submitConfirmationToolEvt());
    document.forms.confirmationEmailForm.submit();
  };

  const bookingType = location?.state?.bookingType;
  const sendGuestEmail = !!location?.state?.bookingEmailOptions?.sendGuestEmail;
  const guestEmail = location?.state?.bookingEmailOptions?.guestEmail;

  useEffect(() => {
    window.utag_data = window.utag_data || {};
    window.utag_data.property_ows_code = hotelCode;
  }, [hotelCode]);

  const {
    cancelation: cancellation,
    rooms,
    totalAdults: _ignoredTotalAdults,
    totalChildren: _ignoredTotalChildren, // ignored because is always 0
    numberOfRooms,
    numberOfNights,
    property,
    webChat,
    ...personalizeData
  } = getPersonalizeData({
    content: {
      accommodations,
      offers,
      property: propertyContent,
      termsAndConditions,
    },
    employee: employeeMode,
    isPrepaid: false,
    language: locale,
    oj: {
      apiErrors: tretailErrors.apiErrors,
      results: {
        ...completedBooking,
        contactDetails: {
          ...completedBooking.contactDetails,
          email: guestEmail || completedBooking.contactDetails.email,
        },
      },
      supplierErrors: tretailErrors.supplierErrors,
    },
    pp: isPreferredPartners,
    promoCode,
    sendGuestEmail,
  });

  useEffect(() => {
    dispatchWithLocale(setParams(webChat));
  }, [JSON.stringify(webChat)]);

  const roomsWithCorrectGuestValues = rooms.map((room, roomIndex) => {
    const { roomGuests } = postBookingComments.comments[roomIndex];
    return {
      ...room,
      adults: roomGuests.filter(({ type }) => type === 1).length,
      children: roomGuests.filter(({ type }) => type === 2).length,
      roomGuests,
    };
  });

  const { totalAdults, totalChildren } = roomsWithCorrectGuestValues.reduce(
    (acc, { adults, children }) => {
      return {
        totalAdults: acc.totalAdults + adults,
        totalChildren: acc.totalChildren + children,
      };
    },
    { totalAdults: 0, totalChildren: 0 }
  );

  const downloadMobileAppUrl = `${MOBILE_APP_URL}?${queryString.stringify({
    confirmationNumber: reservationId,
    hotelCode,
  })}`;

  const confirmationSummary = [
    `${t("Confirmed")}: `,
    getFormattedDateRange(startDate, endDate, locale, "short"),
    property.name.text,
    pluralize(t, numberOfRooms, "Room"),
    t("with"),
    pluralize(t, totalAdults, "Adult"),
    totalChildren > 0
      ? `and ${pluralize(t, totalChildren, "Child", "Children")}`
      : undefined,
  ]
    .filter(Boolean)
    .join(" ");

  const initialArrivalTime =
    getInitialArrivalTime(propertyContent)(completedBooking);

  const webChatStatus = getWebChatStatus({
    isEnabled: webChat?.enabled,
    currentState: useSelector(selectChatStatus),
    step: STEPS.FOUR,
    isUserLoggedIn,
    ...media,
  });

  return {
    ...personalizeData,
    bookingType,
    cancellation,
    completedBooking,
    confirmationSummary,
    contactDetails,
    contactName,
    email,
    employeeMode,
    goToRegisterWithReservation,
    initialArrivalTime,
    isBookingCancelled,
    isBookingCancelledFailed,
    isCancellingBooking,
    isPreferredPartners,
    isUserLoggedIn,
    numberOfNights,
    numberOfRooms,
    phoneNumber,
    postBookingComments,
    ppDetails,
    ppReturnUrl,
    property: {
      ...property,
      typeName: propertyContent.typeName,
    },
    propertyStatusLink,
    propertyTravelAdvisoryLink,
    rooms: roomsWithCorrectGuestValues,
    submitConfirmationTool,
    totalAdults,
    totalChildren,
    downloadMobileAppUrl,
    hotelCode,
    webChatStatus,
    additionalWebChatInfo: webChat,
    reservationId,
    sendGuestEmail,
  };
}
