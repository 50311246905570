import queryString from "query-string";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "hooks";
import { get } from "lodash/fp";
import { useWebChat as useWebChatMicroApps } from "@micro-apps/web-chat";

import { selectProfile, selectIsUserPersistent } from "store/profile";
import { updateChatStatus, webChatAccepted } from "store/appStatus";
import { WEB_CHAT_STATE as STATE } from "fixtures/constants";
import generateSearchMetadata from "utils/generateSearchMetadata";
import env from "config/env";

const { STATIC_SITE_URL } = env;

export function getWebChatContent({
  locale = "en",
  propertyCode,
  signal,
} = {}) {
  return axios
    .get(`${STATIC_SITE_URL}/alt/apps/fshr/feeds/web-chat/availability`, {
      params: {
        language: locale,
        owsCode: propertyCode,
        timestamp: Date.now(),
      },
      signal,
    })
    .then(get("data"))
    .catch((error) => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });
}

export default function useWebChat({
  hotelCode,
  booking,
  bookingError,
  searchParams: initialSearchParams,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const profile = useSelector(selectProfile);
  const isUserPersistent = useSelector(selectIsUserPersistent);

  const [webChatContent, setWebChatContent] = useState(null);

  const { locale, localeIsoCode } = useTranslation();

  const searchParams =
    (initialSearchParams && initialSearchParams.rooms
      ? {
          hotelCode: initialSearchParams.hotelCode,
          promoCode: initialSearchParams.promoCode,
          adults: initialSearchParams.rooms.map(({ adults }) => `${adults}`),
          children: initialSearchParams.rooms.map(
            ({ children }) => `${children}`
          ),
          checkIn: initialSearchParams.dates.checkIn,
          checkOut: initialSearchParams.dates.checkOut,
        }
      : initialSearchParams) || queryString.parse(location.search || "");

  const generateAdditionalMetadata = () =>
    generateSearchMetadata({
      searchParams,
      pathname: window.location.href,
      profile: isUserPersistent ? {} : profile,
      bookingError,
    });

  const onReady = () =>
    dispatch(updateChatStatus({ status: STATE.SHOWN, hotelCode }));

  const onDestroy = () =>
    dispatch(updateChatStatus({ status: STATE.HIDDEN, hotelCode }));

  const onWidgetClose = () =>
    dispatch(updateChatStatus({ status: STATE.CLOSED, hotelCode }));

  const onWidgetOpen = () =>
    dispatch(
      updateChatStatus({
        status: STATE.OPEN,
        hotelCode,
        eventName: "chat_start",
      })
    );

  const onMessageSent = () => dispatch(webChatAccepted());

  useEffect(() => {
    const controller = new AbortController();
    getWebChatContent({
      locale,
      propertyCode: hotelCode,
      signal: controller.signal,
    })
      .then(setWebChatContent)
      .catch(console.log);

    return () => {
      controller.abort();
    };
  }, [locale, hotelCode]);

  const {
    enabled,
    initialized: isInitialised,
    openWebChat,
    setPredefinedMessage,
  } = useWebChatMicroApps({
    webChatContent,
    profile: isUserPersistent ? {} : profile,
    booking,
    propertyCode: hotelCode,
    localeIsoCode,
    options: {
      onReady,
      onDestroy,
      onWidgetOpen,
      onWidgetClose,
      onMessageSent,
      generateAdditionalMetadata,
      hideFloatingChatButton: true,
    },
  });

  return {
    enabled,
    isInitialised,
    openWebChat,
    setPredefinedMessage,
  };
}
