import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import flow from "lodash/fp/flow";
import getOr from "lodash/fp/getOr";
import some from "lodash/fp/some";
import has from "lodash/fp/has";
import { replace } from "connected-react-router";

import { profileBookingPath } from "Profile/profileRoutes";
import { useTranslation, useDispatchWithLocale } from "hooks";
import {
  getUpcomingTripData,
  getUpcomingTripDataCancel,
  selectBookingByReservationId,
  selectBookingHistoryErrors,
  modifyHotelProductCust,
  selectModifyDatesError,
} from "store/bookings";
import {
  selectIsLoadingRequests,
  selectIsRequestFailed,
} from "store/apiRequestStates";
import { selectPropertyContent } from "store/propertyContent";
import { selectPropertyAccommodationsContent } from "store/accommodationsContent";
import { selectPropertyOffersContent } from "store/offersContent";
import { selectTaxesContent } from "store/taxesContent";
import { selectPropertyBookingMessagesContent } from "store/bookingMessages";
import { selectTermsAndConditionsForCurrentBooking } from "store/termsAndConditionsContent";
import canCancelReservation from "utils/canCancelReservation";
import getCancelByDate from "utils/getCancelByDate";
import {
  additionalRequestsPreppers,
  mostImportantPreppers,
  tripPurposePreppers,
  updateComments,
  roomGuestsPreppers,
} from "Profile/utils/updateUpcomingTripMappers";
import { getYouCanBookMessage } from "utils/utils";
import { getReservationHighlights } from "Profile/utils/getReservationHighlights";
import { getEmployeeRate } from "Profile/utils/getEmployeeRate";
import { convertEmployeeDiscoveryRates } from "Profile/utils/convertEmployeeDiscoveryRates";
import {
  fetchBookingComments,
  selectPostBookingComments,
  updatePostBookingComments,
  updateProfileComments,
  selectBookingCommentsError,
} from "store/bookingComments";
import { MediaContext } from "contexts/MediaContext";
import { selectGuestRequestArrivalTransportation } from "store/guestRequests";
import {
  selectBookingConfig,
  selectPhoneNumberPrimary,
  selectTravelArrangements,
  selectWelcomeAmentities,
} from "store/transportationAmenitiesContent";

export const SECTIONS = {
  NONE: "none",
  TIME_ARRIVAL: "time-arrival",
  guest: (index) => `guest-${index}`,
  preferences: (preference) => `preferences-${preference}`,
  SPECIAL_REQUESTS: "special-requests",
  MOST_IMPORTANT_THING: "most-important-thing",
};

const getBookingHasGuarantees = flow(
  getOr([], ["hotelProducts", 0, "guarantees"]),
  some(has("type"))
);

const isCartEnabledAndHasCategories = (obj) => {
  return obj?.cartEnabled && (obj?.categories || []).length > 0;
};

const getCanRequestTransportation = ({
  upcomingTrip,
  availableArrivalTransportations,
}) => {
  return (
    isCartEnabledAndHasCategories(availableArrivalTransportations) &&
    getBookingHasGuarantees(upcomingTrip)
  );
};

const getCanRequestWelcomeAmenities = ({
  upcomingTrip,
  availableWelcomeAmenities,
}) => {
  return (
    isCartEnabledAndHasCategories(availableWelcomeAmenities) &&
    getBookingHasGuarantees(upcomingTrip)
  );
};

export default function useUpcomingTrip({ reservationId, hotelCode, surname }) {
  const { t, locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();
  const media = useContext(MediaContext);

  const taxes = useSelector(selectTaxesContent(hotelCode));

  const upcomingTripDetails = useSelector(
    selectBookingByReservationId({
      reservationId,
    })
  );

  const employeeRate = getEmployeeRate({ upcomingTripDetails });

  const upcomingTrip =
    !!employeeRate && employeeRate.compRate
      ? convertEmployeeDiscoveryRates({ upcomingTripDetails, taxes })
      : upcomingTripDetails;

  const hasUpcomingTrip = Boolean(upcomingTrip?.bookingId);

  // REQUIRED DATA TO RENDER
  // upcomingTrip
  // postBookingComments
  // propertyContent
  // accommodationContent
  // termsAndConditions
  // offersContent
  // taxes
  // bookingMessages
  // countries
  // properties
  // guest requests
  // transportationAmenitiesContent
  const hasAllRequiredData = useSelector((state) => {
    return [
      hasUpcomingTrip
        ? get(state, ["bookings", "byReservationId", reservationId])
        : false,
      hasUpcomingTrip
        ? get(state, ["bookingComments", "byReservationId", reservationId])
        : false,
      get(state, ["propertyContent", "data", hotelCode]),
      get(state, ["accommodationsContent", "data", hotelCode]),
      get(state, ["offersContent", "data", hotelCode]),
      get(state, ["termsAndConditions", "data", hotelCode]),
      get(state, ["taxes", "data", hotelCode]),
      get(state, ["bookingMessages", "data", "availability", hotelCode]),
      get(state, ["countries", "data"], []).length > 0,
      get(state, ["properties", "data"], []).length > 0,
      get(state, ["guestRequests", "data", reservationId]),
      get(state, [
        "transportationAmenitiesContent",
        "byReservationId",
        reservationId,
      ]),
    ].every(Boolean);
  });

  useEffect(() => {
    if (!hasAllRequiredData) {
      dispatchWithLocale(
        getUpcomingTripData({
          reservationId,
          hotelCode,
          surname,
        })
      );
    }

    return () => {
      if (!hasAllRequiredData) {
        dispatchWithLocale(getUpcomingTripDataCancel);
      }
    };
  }, [reservationId, hotelCode, surname]);

  const propertyContent = useSelector(selectPropertyContent(hotelCode));
  const accommodationContent = useSelector(
    selectPropertyAccommodationsContent(hotelCode)
  );
  const offersContent = useSelector(selectPropertyOffersContent(hotelCode));

  const { messages: bookingMessages = [] } = useSelector(
    selectPropertyBookingMessagesContent({
      hotelCode,
      bookingStep: "availability",
    })
  );

  const employeeRateTitle =
    !!employeeRate && employeeRate.title && t(employeeRate.title);

  const [termsAndConditions] = useSelector(
    selectTermsAndConditionsForCurrentBooking({
      locale,
      currentBooking: upcomingTrip,
    })
  );

  const commentSelectorInfo = {
    reservationId,
    bookingId: upcomingTrip?.bookingId,
    productId: get(upcomingTrip, ["hotelProducts", 0, "productId"], ""),
  };

  const currencyCode = get(upcomingTrip, [
    "price",
    "total",
    "cash",
    "currencyCode",
  ]);

  const bookingConfig = useSelector(selectBookingConfig({ reservationId }));
  const phoneNumberPrimary = useSelector(
    selectPhoneNumberPrimary({ reservationId })
  );

  const { comments = [] } = useSelector(
    selectPostBookingComments(commentSelectorInfo)
  );

  const [comment] = comments.filter(
    (c) => c.productId === commentSelectorInfo.productId
  );

  const commentsUpdater = updateComments({
    ...commentSelectorInfo,
    comments,
  });

  // ==========================================================================
  // Arrival Transportation
  // ==========================================================================
  const availableArrivalTransportations = useSelector(
    selectTravelArrangements({ reservationId, currencyCode })
  );

  const arrivalTransportation = useSelector(
    selectGuestRequestArrivalTransportation({ reservationId })
  );

  const canRequestTransportation = getCanRequestTransportation({
    upcomingTrip,
    availableArrivalTransportations,
  });

  const hasTransportation = !isEmpty(arrivalTransportation);
  // ==========================================================================

  // ==========================================================================
  // Welcome Amenities
  // ==========================================================================
  const availableWelcomeAmenities = useSelector(
    selectWelcomeAmentities({ reservationId, currencyCode })
  );

  const canRequestWelcomeAmenities = getCanRequestWelcomeAmenities({
    upcomingTrip,
    availableWelcomeAmenities,
  });
  // ==========================================================================

  const priceViewable = get(
    upcomingTrip,
    ["hotelProducts", 0, "roomRate", "priceViewable"],
    true
  );

  const errors = useSelector(selectBookingHistoryErrors());

  const areRequestsInProgress = useSelector(
    selectIsLoadingRequests([updateProfileComments, modifyHotelProductCust])
  );

  const youCanBookMessage = getYouCanBookMessage({
    propertyContent,
    internalCampaignTrackingCode: "upcomingtrip_web_add_experience",
  });

  const highlightsMap =
    (hasUpcomingTrip &&
      accommodationContent &&
      offersContent &&
      getReservationHighlights({
        upcomingTrip,
        accommodationContent,
        offersContent,
        employeeRateTitle,
      })) ||
    new Map();

  const checkInDate = get(
    upcomingTrip,
    ["hotelProducts", 0, "checkInDate"],
    ""
  );

  const allowCancellation = canCancelReservation({
    upcomingTrip,
    termsAndConditions,
    propertyTimezone: propertyContent?.timeZone,
    checkInDate,
  });

  const cancelByDate = getCancelByDate({
    checkInDate,
    termsAndConditions,
    locale,
  });

  const phoneNumber =
    propertyContent?.reservationPhone || propertyContent?.propertyPhone;

  const fetchingError = useSelector(
    selectIsRequestFailed(fetchBookingComments.type)
  );
  const saveCommentError = useSelector(
    selectIsRequestFailed(updatePostBookingComments.type)
  );
  const saveBookingRequestError = useSelector(
    selectIsRequestFailed(modifyHotelProductCust.type)
  );
  const hasRequestErrors =
    errors.length > 0 ||
    fetchingError ||
    saveCommentError ||
    saveBookingRequestError;

  const updatePostBookingCommentsError = useSelector(
    selectBookingCommentsError
  );
  const saveBookingError = useSelector(selectModifyDatesError);
  const hasErrors = [
    errors.length > 0,
    updatePostBookingCommentsError,
    saveBookingError,
  ].some(Boolean);

  const handleChangeArrivalTime = ({ arrivalTime }) =>
    dispatchWithLocale(
      modifyHotelProductCust({
        bookingId: commentSelectorInfo.bookingId,
        arrivalTime,
        locale,
      })
    );

  const updateComment =
    (prepper, specialRequestChanged = false) =>
    (formValues) =>
      dispatchWithLocale(
        updateProfileComments({
          ...commentsUpdater(prepper(formValues)),
          specialRequestChanged,
        })
      );

  const handleUpdateTripPurpose = updateComment(tripPurposePreppers.update);

  const handleUpdateMostImportantThing = updateComment(
    mostImportantPreppers.update
  );

  const handleUpdateAdditionalRequests = updateComment(
    additionalRequestsPreppers.update,
    true
  );

  const handleUpdateRoomGuests = updateComment(roomGuestsPreppers.update);

  const handleUpdateTravelDates = ({
    startDate: newCheckInDate,
    endDate: checkOutDate,
  }) =>
    dispatchWithLocale(
      modifyHotelProductCust({
        bookingId: commentSelectorInfo.bookingId,
        checkInDate: newCheckInDate,
        checkOutDate,
        locale,
        actions: [
          replace({
            pathname: profileBookingPath.to({
              reservationId,
              hotelCode,
              locale,
            }),
          }),
        ],
      })
    );

  const webChatEnabled = !employeeRate && !media.isMobileApp;

  const searchParams = {
    hotelCode: propertyContent?.owsCode,
    dates: {
      checkIn: upcomingTrip?.startDate,
      checkOut: upcomingTrip?.endDate,
    },
    rooms: [
      get(comment, ["roomGuests"], []).reduce(
        (acc, { type }) => ({
          adults: type === 1 ? acc.adults + 1 : acc.adults,
          children: type === 2 ? acc.children + 1 : acc.children,
        }),
        { adults: 0, children: 0 }
      ),
    ],
    promoCode: "",
  };

  return {
    bookingConfig,
    phoneNumberPrimary,
    ...commentSelectorInfo,
    upcomingTrip,
    propertyContent,
    taxes,
    bookingMessages,
    comment,
    priceViewable,
    hasErrors,
    showLoadingSpinner: !hasAllRequiredData,
    showSavingSpinner: areRequestsInProgress && !hasRequestErrors,
    youCanBookMessage,
    highlightsMap,
    phoneNumber,
    allowCancellation,
    canChangeBooking: allowCancellation,
    employeeRate,

    changeArrivalTime: handleChangeArrivalTime,
    updateTripPurpose: handleUpdateTripPurpose,
    updateMostImportantThing: handleUpdateMostImportantThing,
    updateAdditionalRequests: handleUpdateAdditionalRequests,
    updateRoomGuests: handleUpdateRoomGuests,
    updateTravelDates: handleUpdateTravelDates,
    cancelByDate,

    // WebChat
    webChatEnabled,
    searchParams,

    // Welcome Amentities
    canRequestWelcomeAmenities,
    availableWelcomeAmenities,

    // Arrival Transportation
    hasTransportation,
    availableArrivalTransportations,
    arrivalTransportation,
    canRequestTransportation,
  };
}
