import { ofType } from "redux-observable";
import { of } from "rxjs";
import {
  switchMap,
  takeUntil,
  map,
  mergeMap,
  catchError,
  withLatestFrom,
} from "rxjs/operators";

import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import {
  addOnRequestEventProduct$,
  getBookingById$,
} from "api/tretail/booking";
import { modifyHotelProductCust$ } from "api/tretail/bookingManagementCust";
import { getGuestRequests$ } from "api/messenger";

import {
  addRequestEvent,
  addRequestEventFulfilled,
  addRequestEventFailed,
  addRequestEventCancel,
  fetchGuestRequestsFulfilled,
} from "../guestRequests.slice";

const addRequestEventEpic = (action$, state$) =>
  action$.pipe(
    ofType(addRequestEvent.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            requestId,
            bookingId,
            surname,
            propertyCode,
            reservationId,
            formData,
            locale,
            isOnItineraryPage,
          },
        },
      ]) => {
        return ajaxWithHealthCheck$({
          locale,
        }).pipe(
          switchMap(() =>
            addOnRequestEventProduct$({
              bookingId,
              formData,
              locale,
            })
          ),

          switchMap(({ productIds }) =>
            getBookingById$({ bookingId, locale }).pipe(
              map((booking) => ({
                productIds,
                booking,
              }))
            )
          ),

          switchMap(({ productIds, booking }) =>
            modifyHotelProductCust$({
              bookingId,
              locale,
              bookingEmailOptions: { suppress: true },
            }).pipe(map(() => ({ productIds, booking })))
          ),

          switchMap(({ productIds, booking }) =>
            getGuestRequests$({ propertyCode, reservationId, surname }).pipe(
              map((guestRequests) => {
                return {
                  productIds,
                  booking,
                  guestRequests,
                };
              })
            )
          ),

          mergeMap(({ productIds, booking, guestRequests }) => [
            fetchGuestRequestsFulfilled({
              reservationId,
              guestRequests,
            }),
            addRequestEventFulfilled({
              requestId,
              hotelCode: propertyCode,
              booking,
              productIds,
              isOnItineraryPage,
            }),
          ]),

          catchError((error) => {
            return of(addRequestEventFailed({ requestId, error }));
          }),

          takeUntil(action$.pipe(ofType(addRequestEventCancel.type)))
        );
      }
    )
  );

export default addRequestEventEpic;
